@import "../../global.scss";

li {
  font-size: 16px;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;

  &.active {
    background-color: $mainColor;
    color: white;
  }
}

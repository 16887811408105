@import "../../global.scss";

.navbar {
  background-color: white;
  color: $mainColor;
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  transition: all 2s ease;

  .wrapper {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a,
    a:visited,
    a:hover,
    a:active {
      color: inherit;
      outline: 0;
    }

    .left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 28px;
        text-decoration: none;
        font-weight: 700;
        margin-right: 50px;
      }

      .item {
        display: flex;
        gap: 10px;
        font-size: 14px;
        margin-right: 15px;

        @include mobile {
          display: none;
        }
      }
    }

    .right {
      .hamburger {
        width: 24px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 1s ease;
        }
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: white;

    .hamburger {
      span {
        overflow: hidden;
        width: 20px;

        &:first-child,
        &:last-child {
          background-color: white;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:first-child {
          transform: rotate(45deg);
        }

        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@import "../../global.scss";

.about {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 40px;
    margin: 100px 0;

    @include mobile {
      margin: 50px 0;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    .leftSide {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .clock {
        overflow: hidden;
        position: relative;
        height: 200px;
        width: 200px;
        background: white;
        box-sizing: border-box;
        border-radius: 50%;
        border: 10px solid $mainColor;

        .top {
          position: absolute;
          width: 3px;
          height: 8px;
          background: #262626;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
        .right {
          position: absolute;
          width: 8px;
          height: 3px;
          background: #262626;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto 0;
        }
        .bottom {
          position: absolute;
          width: 3px;
          height: 8px;
          background: #262626;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
        }
        .left {
          position: absolute;
          width: 8px;
          height: 3px;
          background: #262626;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto 0;
        }
        .center {
          height: 6px;
          width: 6px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background: #262626;
          border-radius: 100%;
        }
        .shadow {
          height: 200px;
          width: 200px;
          position: absolute;
          left: 60px;
          top: 60px;
          transform: rotate(135deg);
          background: linear-gradient(transparent, rgba(#000, 0.1));
        }
        .hour {
          width: 3px;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          //animation: time 86400s infinite linear;
          animation: time 60s infinite linear;
          &:before {
            position: absolute;
            content: "";
            background: #262626;
            height: 60px;
            width: 3px;
            top: 30px;
          }
        }
        .minute {
          width: 1px;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          //animation: time 3600s infinite linear;
          animation: time 30s infinite linear;
          &:before {
            position: absolute;
            content: "";
            background: #262626;
            height: 40px;
            width: 1px;
            top: 50px;
          }
        }
        .second {
          width: 2px;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          //animation: time 60s infinite linear;
          animation: time 15s infinite linear;
          &:before {
            position: absolute;
            content: "";
            background: #fd1111;
            height: 45px;
            width: 2px;
            top: 45px;
          }
        }
      }

      @keyframes time {
        to {
          transform: rotate(360deg);
        }
      }
    }

    .rightSide {
      flex: 1;
      padding: 10px;
      display: flex;
      flex-direction: column;

      @include mobile {
        align-items: center;
        height: 600px;
      }

      .desc {
        font-weight: 400;
        width: 70%;
        margin-bottom: 50px;
        letter-spacing: 0.5px;

        color: $textDark;
        @include mobile {
          text-align: center;
          height: 220px;
          margin-bottom: 30px;
        }
      }

      .contact {
        display: inline-block;
        text-decoration: none;
        padding: 20px;
        background: $colorLight;
        color: $textDark;
        border-radius: 15px;
        width: max-content;

        @include mobile {
        }

        &:hover {
          animation: nudge 0.5s ease;
          animation-iteration-count: 3;
        }

        @keyframes nudge {
          50% {
            transform: translateY(5px);
          }
        }
      }
    }
  }
}

@import "../../global.scss";

.menu {
  width: 300px;
  height: 100vh;
  background-color: $mainColor;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  @include mobile {
    width: 100vw;
    right: -100vw;
  }

  &.active {
    right: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: white;
    font-weight: 300;
    width: 60%;

    li {
      margin-bottom: 20px;
      a {
        font-size: 24px;
        color: inherit;
        text-decoration: none;
      }

      &:hover {
        font-weight: 500;
      }
    }
  }
}

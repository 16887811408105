$mainColor: #446d88;
$colorLight: #c9d9e4;
$textDark: #37576d;

$width: 768px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}

@import "../../global.scss";

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 40px;
    margin: 20px 0;
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 50px;
  }

  .container {
    width: 70%;
    display: grid;

    grid-template-columns: repeat(3, 1fr);

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }

    .item {
      width: 300px;
      height: 220px;
      border-radius: 10px;
      border: 1px solid lightgray;
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all 0.5s ease;

      @include mobile {
        width: 150px;
        height: 120px;
      }
      .desc {
        position: absolute;
        color: white;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          margin-bottom: 5px;
        }

        p {
          margin-bottom: 10px;
          width: 85%;
        }

        .buttons {
          display: flex;
          gap: 10px;
          height: 50px;

          .btn {
            padding: 10px;
            color: white;
            text-decoration: none;
            border: 1px solid transparent;
            transition: all 0.5s ease;
            border-radius: 5px;
            display: flex;
            align-self: center;
            justify-content: center;

            &:hover {
              border-color: $colorLight;
              transform: translateY(-2px);
            }
          }
        }
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 2;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}

@import "../../global.scss";

.experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-bottom: 70px;
    font-size: 40px;

    @include mobile {
      margin-bottom: 10px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-bottom: 30px;

    @include mobile {
      flex-direction: column;
    }
  }

  .left,
  .right {
    width: 28%;
    display: flex;
    flex-direction: column;
    padding: 40px 60px;
    background-color: $colorLight;
    border: 1px solid transparent;
    border-radius: 20px;
    transition: all 0.5s ease;
    @include mobile {
      width: 90%;
      padding: 10px;
    }

    &:hover {
      border: 1px solid $colorLight;
      background-color: transparent;
    }

    h2 {
      align-self: center;
      margin-bottom: 30px;
      color: black;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 20px;
      transition: all 0.3s ease;
      color: $textDark;

      &:hover {
        transform: translateY(-2px);
      }

      .item-title {
        margin-bottom: 5px;
        font-weight: 500;
      }
      .item-value {
        font-size: 12px;
        font-weight: 300;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .item {
        width: 175px;
        margin-bottom: 20px;
      }
    }
  }

  .resume {
    text-decoration: none;
    background-color: $mainColor;
    padding: 15px 20px;
    color: white;
    border-radius: 10px;
    transition: all 0.5s ease;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $mainColor;
      background-color: white;
      color: black;
    }
  }
}

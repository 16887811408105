@import "../../global.scss";

.intro {
  background: white;
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 1;
    overflow: hidden;

    @include mobile {
      display: none;
    }

    .img-container {
      width: 700px;
      height: 700px;
      background-color: #da667b;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;

      img {
        height: 98%;
      }
    }
  }

  .right {
    flex: 1;
    position: relative;

    .wrapper {
      height: 100%;
      padding-left: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        text-align: center;
        padding: 0;
      }

      h1 {
        font-size: 60px;
        margin: 10px 0;
      }
      h2 {
        font-size: 20px;
      }

      h3 {
        font-size: 24px;

        span {
          font-size: inherit;
          color: #da667b;
        }
        .ityped-cursor {
          animation: blink 0.5s infinite;
        }

        @keyframes blink {
          100% {
            opacity: 0;
          }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 35%;
      color: inherit;
      height: 70px;

      @include mobile {
        left: 44%;
      }

      &:focus {
        outline: 0;
      }

      .img {
        font-size: 48px;
        animation: arrow 1.5s infinite;
      }

      @keyframes arrow {
        50% {
          transform: translateY(5px);
        }
      }
    }
  }
}

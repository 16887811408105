@import "../../global.scss";
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 40px;
    margin-bottom: 50px;

    @include mobile {
      margin: 10px 0;
      height: 75px;
    }
  }

  .wrapper {
    display: flex;
    width: 80%;

    @include mobile {
      flex-direction: column;
      width: 100%;
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .item {
        width: 250px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $colorLight;
        border-radius: 20px;
        border: 1px solid transparent;
        justify-content: center;
        transition: all 0.5s ease;

        @include mobile {
          position: relative;
        }

        &:hover {
          background-color: $mainColor;
          opacity: 0.7;
          border: 1px solid $mainColor;
          color: white;
        }

        .item-icon {
          font-size: 20px;
          margin-bottom: 10px;

          @include mobile {
            position: absolute;
            top: 35%;
            left: 10px;
          }
        }

        .item-value {
          margin-bottom: 15px;
          @include mobile {
            margin: 0;
          }
        }

        .item-button {
          text-decoration: none;
          padding: 10px 10px;
          transition: all 0.5s ease;
          color: inherit;
          border-radius: 8px;
          border: 1px solid transparent;

          &:hover {
            transform: translateY(-2px);
            border-color: white;
          }
        }
      }
    }

    .right {
      flex: 1;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        margin-bottom: 30px;
        font-size: 24px;
        text-align: left;

        @include mobile {
          height: 50px;
          margin-bottom: 10px;
        }
      }

      form {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 400px;

        .input {
          padding: 10px;
          height: 30px;
          width: 90%;
          margin-bottom: 20px;
          border-radius: 15px;
          border: 1px solid $mainColor;
          @include mobile {
            margin-bottom: 10px;
          }
        }
        textarea {
          width: 90%;
          height: 100px;
          border-radius: 15px;
          padding: 10px;
          margin-bottom: 30px;
          resize: none;
          border: 1px solid $mainColor;
          word-wrap: break-word;

          @include mobile {
            margin-bottom: 15px;
          }
        }

        .submit {
          display: flex;
          padding: 20px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: max-content;
          background-color: hsl(
            204,
            33%,
            calc(var(--lightness-offset, 0%) + 40%)
          );
          color: white;
          border-radius: 15px;
          transition: all 0.5s ease;
          margin: 0;

          border: 1px solid transparent;

          @include mobile {
            padding: 10px;
          }

          &:hover {
            border: 1px solid $mainColor;
            background-color: white;
            color: black;
          }
        }
      }
    }
  }
}
